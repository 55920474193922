import { CustomImageContextProvider } from '@/contexts/CustomImageContext'
import { NonUrlContextProvider } from '@/contexts/NonUrlContext'
import { ImageSide, WishListItem } from '@/utils/APIRouteTypes'
import React from 'react'
import AggregatedImage from '../imageViewer/aggregatedImage'
import AggregatedImage2 from '../imageViewer/aggregatedImage2'
import { cn } from '@/lib/utils'
import Image from 'next/image'

type Props = {
    item: WishListItem
    className?: string
    width?: number
}

export default function ImageFromWishlistItem({ item, className, width }: Props) {
    const contextValue = item.context
    const isFront = contextValue ? contextValue.frontImages.length >= contextValue.backImages.length : item.nc_productDetails.designOnFront
    const imgSrc = item.selectedColor?.images.filter(y => isFront ? y.side === ImageSide.FRONT : y.side === ImageSide.BACK)[0].image as string
    if (!contextValue)

        return <Image src={imgSrc} className={cn(className)} alt='Your Design' width={width || 500} height={0} />

    const boundingBox = isFront ? item.boundingBoxFront : item.boundingBoxBack
    return (
        <CustomImageContextProvider defaultProps={contextValue} isStatic >
            <NonUrlContextProvider sizes={item.selectedSize ? [item.selectedSize] : []} colors={item.selectedColor ? [item.selectedColor] : []} sampleImages={[]}>
                <div className={cn(' overflow-hidden', className)}>
                    {boundingBox && item.colorsExist ? <AggregatedImage side={isFront ? "front" : 'back'} imgSrc={imgSrc || ''} boundingBox={boundingBox} imgHeight={item.imgHeight} imgWidth={item.imgWidth} tshirtAltText='Wish List Item' className='-my-2' /> :
                        <AggregatedImage2 boundingBox={boundingBox} cameraBox={item.selectedSize?.cameraBox} isCircular={item.selectedSize?.isCircular || false} />
                    }
                </div>
            </NonUrlContextProvider>
        </CustomImageContextProvider>

    )
}
