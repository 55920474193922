'use client'

import { useCart } from '@/hooks/useCart'
import { CartItemType } from '@/utils/APIRouteTypes'
import { getCorrectProductDetails } from '@/utils/helper'
import ProductPrice from '../detailComponents/productPrice'
import ImageFromWishlistItem from '../others/ImageFromWIshListItem'
import { Button } from '../ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'


type props = {
    item: CartItemType
}

export default function CartDrawerItem({ item }: props) {

    const colorName = item.selectedColor?.name
    const cart = useCart()
    const productDetails = getCorrectProductDetails(item)
    const handleQtyUpdate = (qty: string) => {
        cart.updateQty(item.id, Number(qty))
    }

    const handleRemove = () => {
        cart.removeItem(item.id)
    }

    return (
        <div className='flex border-b w-full px-1 py-2 gap-4 max-h-[200px] overflow-hidden my-2'>
            <ImageFromWishlistItem item={item} className='max-w-24 max-h-[200px]' width={100} />
            <div className=' flex items-start flex-col w-full'>
                <p>
                    <h3 className='font-bold -mb-2 line-clamp-1'>
                        {productDetails.name}
                    </h3>
                    <span className='text-sm text-slate-500'>
                        {colorName} - {item.selectedSize?.name}
                    </span>
                </p>
                <div className='flex justify-between w-full'>
                    <div className='w-full'>
                        <Select value={String(item.quantity)} disabled={cart.loading} onValueChange={handleQtyUpdate}>
                            <SelectTrigger className="w-fit mt-1" >
                                <SelectValue placeholder="Quantity" />
                            </SelectTrigger>
                            <SelectContent className="z-[999]">
                                {Array.from(Array(9).keys()).map(i => (
                                    <SelectItem key={i + 1} value={String(i + 1)}>
                                        {String(i + 1)}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <Button onClick={handleRemove} variant='link' className='p-0' disabled={cart.loading}>Remove</Button>
                    </div>

                    <ProductPrice price={productDetails.price} MRP={productDetails.MRP} hideTax className='me-2' right />

                </div>
            </div>
        </div>
    )
}


export const CartDrawerItemSkeleton = () => {
    return (

        <div className='flex border-b w-full py-2 px-2'>
            <div className="skeleton h-32 w-24"></div>
            <div className='ms-1 flex justify-center items-start flex-col'>

                <div className="skeleton h-7 w-32 my-1"></div>

                <div className="skeleton h-9 w-10 my-1"></div>
                <div className="skeleton h-4 w-20 my-1"></div>
            </div>
        </div>

    )
}
