import { cn } from '@/lib/utils'
import React from 'react'

type Props = {
    price: number
    MRP: number
    className?: string
    hideTax?: boolean
    bigFont?: boolean
    right?: boolean
}

export default function ProductPrice(props: Props) {
    return (
        <div className={cn('mt-2 flex-col flex text-xs sm:text-sm', props.className, {
            "items-end": props.right
        })}>
            <p className={cn(' font-normal flex p-0', props.bigFont ? "text-2xl" : "sm:text-xl text-lg")}>
                <span className='text-sm self-start justify-self-start font-light mt-1'>
                    ₹
                </span>
                {props.price}
                {!props.hideTax && <span className='ms-1 text-xs font-normal self-center leading-3 text-slate-500'>(Incl. of all taxes)</span>}
            </p>
            <div className={cn({
                'text-end': props.right,
            })}>
                <span className="my-0 font-light leading-4 text-red-700">
                    ({Math.round(((1 - (props.price / (props.MRP))) * 100))}% OFF)
                </span>
                <p className="my-0 leading-3 text-slate-500 text-xs sm:text-sm font-light">
                    M.R.P:
                    <span className=" line-through ">
                        &nbsp;₹{props.MRP}
                    </span>
                    &nbsp;
                </p>
            </div>
        </div>
    )
}
