'use client';

import { useEffect, useRef } from 'react';
import { useSession } from 'next-auth/react'
import { sendGTMEvent } from '@next/third-parties/google';

const Tracker: React.FC = () => {
  const { data: session, status } = useSession()
  useEffect(() => {
    if (session?.user?.email && status === 'authenticated') {

      sendGTMEvent({
        event: 'login',
        authentication_method: session?.additional?.provider,
        user_id: session?.additional?.id,
      })
    }
    else {
    }
  }, [session]);


  return <></>;
};

export default Tracker;
