"use client"
import { Tooltip as TooltipBase } from 'react-tooltip'
import React from 'react'

export default function Tooltip() {
	return (
		<TooltipBase
			id='tooltip'
			className='!px-1.5 !py-2 !text-xs !sm:text-base'
			style={{
				zIndex: 90
			}}
		/>
	)
}
