'use client'
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle
} from "@/components/ui/sheet"

import { useCommonContext } from "@/contexts/CommonContext"
import { useCart } from "@/hooks/useCart"
import { Properties } from "@/utils/properties"
import { X } from "lucide-react"
import { useRouter } from "next/navigation"
import { Button } from "../ui/button"
import CartDrawerItem, { CartDrawerItemSkeleton } from "./cartDrawerItem"
import { getCorrectProductDetails } from "@/utils/helper"

export default function CartDrawer() {
    const [commonCtx, setCommonCtx] = useCommonContext()
    const router = useRouter()
    const cart = useCart()

    const handleClose = () => {
        setCommonCtx(prev => ({ ...prev, cartDrawerOpen: false }))
    }

    const redirectToProductList = () => {
        router.push(Properties.routes.PRODUCT_LIST)
        handleClose()
    }

    const price = cart.cartItems.reduce((acc, x) => acc + (getCorrectProductDetails(x)?.price * x.quantity), 0)

    return (
        <Sheet open={commonCtx.cartDrawerOpen} onOpenChange={handleClose}>
            <SheetContent className="w-[95%] sm:w-[400px] py-2 h-full px-2" side={'right'}>
                <SheetHeader className="border-b pb-2 px-1 w-full">
                    <div className="flex w-full justify-between items-center">
                        <SheetTitle>Cart</SheetTitle>
                        <SheetClose>
                            <X />
                        </SheetClose>
                    </div>
                </SheetHeader>
                <div className='overflow-y-auto -mr-2'>
                    {cart.loading && cart.cartItems.length == 0 ? <><CartDrawerItemSkeleton />
                        <CartDrawerItemSkeleton />
                        <CartDrawerItemSkeleton />
                        <CartDrawerItemSkeleton />
                    </> :
                        cart.cartItems.length > 0 ? cart.cartItems.map((item, index) => (
                            <CartDrawerItem item={item} key={item.id} />
                        )) : <div className="w-full mt-32 text-slate-500 flex flex-col items-center justify-center">
                            Your cart is empty.
                            <Button
                                onClick={redirectToProductList}
                                className="mt-2 rounded-full"
                            >Start Shopping</Button>
                        </div>}
                </div>
                {price > 0 && <SheetFooter className="border-t mt-auto">
                    <p className="flex justify-between md:text-lg font-semibold px-1 py-2">
                        <span>Grand Total:</span>
                        <span>₹{price}</span>
                    </p>
                    <Button disabled={cart.loading} onClick={cart.handleCheckout}>
                        Checkout
                        {cart.loading && <span className="loading loading-spinner loading-xs ms-2" />}
                    </Button>
                </SheetFooter>
                }
            </SheetContent>
        </Sheet>

    )
}
